/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:0cc61c4f-e378-4e0f-97a8-b967983fccba",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_ldTYklURe",
    "aws_user_pools_web_client_id": "4nofge388rupp4tj5mpjtqc6s",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api96c29da5",
            "endpoint": "https://pvi42487pj.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
