import Vue from "vue";
import App from "./App.vue";

import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import router from "./router";

Vue.config.productionTip = false;

Amplify.configure(awsconfig);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
