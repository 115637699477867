<template>
  <div class="py-5">
    <router-view />
    <div v-if="loading">Loading</div>
    <template v-else>
      <h3>Production</h3>
      <hr />
      <div v-for="(a, pi) in prodApps" :key="pi">
        <p>
          <strong>
            {{ a.app.name.replace("ipp-webapps-prod-", "") }}
          </strong>
          <span v-if="a.app.name.indexOf('symphony') > -1">
            - How to <router-link to="/symphony">log in</router-link></span
          >
        </p>
        <ul>
          <li v-for="(b, pbi) in a.branches" :key="pbi">
            <a :href="createUrl(a, b)" target="_blank">{{ b.branchName }}</a>
            <span v-if="b.jobs.length">
              <span v-if="b.jobs[0].status == 'RUNNING'"> - Deploying</span>
              <span v-else>
                - Last deployment {{ b.jobs[0].endTime | formatDate }}
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="pb-3"></div>
      <h3>Test</h3>
      <hr />
      <div v-for="(a, ti) in testApps" :key="ti">
        <p>
          <strong>
            {{ a.app.name.replace("ipp-webapps-test-", "") }}
          </strong>
        </p>
        <ul>
          <li v-for="(b, tbi) in a.branches" :key="tbi">
            <a :href="createUrl(a, b)" target="_blank">{{ b.branchName }}</a>
            <span v-if="b.jobs.length">
              <span v-if="b.jobs[0].status == 'RUNNING'"> - Deploying</span>
              <span v-else>
                - Last deployment {{ b.jobs[0].endTime | formatDate }}
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="pb-3"></div>
      <hr />
      <p>
        Note: If you are not seeing latest updates please clear your browser
        cache.
      </p>
    </template>
  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { API } from "aws-amplify";
import dayjs from "dayjs";
export default {
  name: "App",
  // created() {
  //   onAuthUIStateChange((authState, authData) => {
  //     this.authState = authState;
  //     this.user = authData;
  //     if (authState == "signedin") {
  //       this.getApps();
  //     }
  //   });
  // },
  data() {
    return {
      user: undefined,
      authState: undefined,
      apps: [],
      loading: false,
    };
  },
  filters: {
    formatDate(str) {
      return dayjs(str).format("D MMM YYYY @ HH:mm");
    },
  },
  computed: {
    prodApps() {
      return this.apps.filter((app) => {
        return app.app.name.indexOf("webapps-prod") > -1;
      });
    },
    testApps() {
      return this.apps.filter((app) => {
        return (
          app.app.name.indexOf("webapps-test") > -1
        );
      });
    },
    viewsApps() {
      return this.apps.filter((app) => {
        return (
          app.app.name.indexOf("webapps-views") > -1
        );
      });
    },
  },
  // beforeDestroy() {
  //   return onAuthUIStateChange;
  // },
  mounted() {
    this.getApps();
  },
  methods: {
    createUrl(a, b) {
      let url = ["https://"];
      url.push(b.branchName.replace("/", "-").replace(".", "-"));
      url.push(".");
      url.push(a.app.defaultDomain);
      url.push("?t=");
      url.push(new Date().getTime());
      return url.join("");
    },
    getApps() {
      this.loading = true;
      this.apiGetApps()
        .then((res) => {
          this.apps = res;
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async apiGetApps() {
      const apiName = "api96c29da5";
      const path = "/apps";
      // const myInit = {
      //   headers: {
      //     Authorization: `Bearer ${(await Auth.currentSession())
      //       .getIdToken()
      //       .getJwtToken()}`,
      //   },
      //   response: true,
      //   queryStringParameters: {},
      // };

      return await API.get(apiName, path);
      // .then((response) => {
      //   // Add your code here
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error.response);
      // });
    },
  },
};
</script>

<style>
amplify-sign-out {
  position: absolute;
  z-index: 10;
  right: 1rem;
  top: 1rem;
}
</style>
