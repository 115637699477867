<template>
  <div id="app">
    <!-- <div v-if="authState !== 'signedin'">You are signed out.</div> -->
    <div class="container">
      <router-view />
    </div>
    <!-- <amplify-authenticator>
      <div class="container" v-if="authState === 'signedin' && user">
      </div>
      <amplify-sign-out></amplify-sign-out>
    </amplify-authenticator> -->
  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { API } from "aws-amplify";
import dayjs from "dayjs";
export default {
  name: "App",
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
    };
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
  methods: {
    apiGetApps() {
      const apiName = "api96c29da5";
      const path = "/apps";
      // const myInit = {
      //   headers: {
      //     Authorization: `Bearer ${(await Auth.currentSession())
      //       .getIdToken()
      //       .getJwtToken()}`,
      //   },
      //   response: true,
      //   queryStringParameters: {},
      // };

      return API.get(apiName, path);
      // .then((response) => {
      //   // Add your code here
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error.response);
      // });
    },
  },
};
</script>

<style>
amplify-sign-out {
  position: absolute;
  z-index: 10;
  right: 1rem;
  top: 1rem;
}
</style>
